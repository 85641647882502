import React from "react";

const Toc = props => {
  return (
    <div className="toc">
      <h5 className="toc__title">この記事の内容</h5>
      <div
        className="toc__content"
        dangerouslySetInnerHTML={{
          __html: props.data,
        }}
      />
    </div>
  );
};

export default Toc;