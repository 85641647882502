import * as React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Toc from "../components/toc"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { RiPriceTagLine, RiArrowLeftCircleLine, RiArrowRightCircleLine } from "react-icons/ri/"

const PostTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const title = post.frontmatter.title
  const tags = post.frontmatter.tags
  const { previous, next } = data
  const cover = getImage(post.frontmatter.cover)
  const imageURL = post.frontmatter.cover?.publicURL
  const tocData = post.tableOfContents

  let toc = ""
  if(tocData) {
    toc = <Toc data={data.markdownRemark.tableOfContents} />
  }

  let coverImage = (cover) ? (<GatsbyImage image={cover} alt={title} />) : ""

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={title}
        description={post.frontmatter.description || post.excerpt}
        imageURL={imageURL}
      />
      <article
        className="blog-post"
        itemScope
        itemType="http://schema.org/Article"
      >
        <header>
          <h1 itemProp="headline">{title}</h1>
          <p className="post-meta">
            {post.frontmatter.date}
            {tags.map(tag =>
              <Link to={`/tags/${tag}`}>
              <span key={tag}>
                <RiPriceTagLine size={17} /> {tag}
              </span>
              </Link>
            )}
          </p>
        </header>
        <div
          style={{
            marginBottom: 50,
          }}
        >
        {coverImage}
        </div>
        {toc}
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
        />
        <hr />
      </article>
      <nav className="blog-post-nav">
        <ul
          style={{
            display: `flex`,
            flexWrap: `wrap`,
            justifyContent: `space-between`,
            listStyle: `none`,
            padding: 0,
          }}
        >
          <li>
            {previous && (
              <Link to={previous.fields.slug} rel="prev">
                <span>
                  <RiArrowLeftCircleLine size={17} /> {previous.frontmatter.title}
                </span>
              </Link>
            )}
          </li>
          <li>
            {next && (
              <Link to={next.fields.slug} rel="next">
                <span>
                  {next.frontmatter.title} <RiArrowRightCircleLine size={17} />
                </span>
              </Link>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default PostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      tableOfContents
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
        cover {
          childImageSharp {
            gatsbyImageData(
              width: 800
              height: 360
              quality: 90
              placeholder: BLURRED
              transformOptions: {cropFocus: CENTER}
              layout: CONSTRAINED
            )
          }
          publicURL
        }
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
